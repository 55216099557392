import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from '@fc-vehicles/components';
import moment from 'moment-timezone';

@Pipe({
  name: 'sortTimelineByDate',
  standalone: true,
})
export class SortTimelineByDatePipe implements PipeTransform {
  transform(
    value: {
      key: string;
      value: {
        duration: Duration;
        totalTime: number;
        width: number;
        position: number;
      }[];
    }[],
  ): {
    key: string;
    value: {
      duration: Duration;
      totalTime: number;
      width: number;
      position: number;
    }[];
  }[] {
    return value.sort(
      (a, b) =>
        moment(a.value[0].duration.upper).unix() -
        moment(b.value[0].duration.upper).unix(),
    );
  }
}
