import {
  Component,
  effect,
  HostBinding,
  OnDestroy,
  OnInit,
  Signal,
  viewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromVehicles from '../../store/reducers';
import {
  getVehicleStats,
  loadMaintenanceSchedules,
  loadVehiclePhotos,
} from '../../store';
import { Observable } from 'rxjs';
import { Vehicle, VehicleGroup } from '@fc-core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  getAllRawVehicles,
  getAllVehicleGroups,
  getLoadingState,
  getSelectedFilters,
  getUsedVehicleTypes,
  getVehicleGroupSortBy,
  getVehicleGroupSortByList,
  getVehicleSortBy,
  getVehicleSortByList,
  loadUsedVehicleTypes,
  loadVehicleGroups,
  loadVehicles,
  loadVehicleTypes,
  resetDefaultVehicleFilters,
  resetDefaultVehicleGroupFilters,
  selectAllVehicles,
  selectRouterParam,
  selectRouterParamData,
  selectVehicleId,
  setManyVehicleFilters,
  setSearchVehicleGroupText,
  setSearchVehicleText,
  setVehicleGroupFilterId,
  setVehicleGroupSortBy,
  setVehiclesFilter,
  setVehicleSortBy,
} from '../../../store';
import {
  FilterGroup,
  FilterItem,
} from '@fc-shared/ui/vehicle-filter/vehicles-filter.component';
import { SortBy } from '@fc-shared/ui/sort-by/sort-by';
import { getAllHardware } from '@fc-store/actions/hardware.actions';
import { getAllHarness } from '@fc-store/actions/harness.actions';
import { isMobile } from '@fc-shared/utils/is-mobile';
import { HeaderPanelComponent } from '@fc-core/header-panel/header-panel/header-panel.component';
import { VehicleOptions } from '@fc-core/models/vehicle/vehicle-options';
import { vehicleLoadOptions } from '@fc-vehicles/containers/vehicle-page/vehicle-load-options';

@Component({
  selector: 'fc-vehicle-page',
  template: `
    <fc-page-container [itemSelected]="!!vehicleId()">
      <div
        class="sidenav"
        [ngClass]="{
          'vehicle-selected': vehicleId(),
        }"
      >
        <div class="vehicle-page-header">
          <fc-header-panel
            #headerPanel
            (searchEmitter)="onVehicleSearch($event)"
            (sortEmitter)="onSortByChange($event)"
            [sortList]="sortByList$ | async"
            [selectedSort]="sortBy()?.value"
            [title]="'Vehicles'"
            [showFilter]="pageType() === 'vehicles'"
          >
            <fc-filter-list
              [filterGroups]="vehicleTypes$ | async"
              [selectedFilters]="selectedFilters()"
              (filterSelect)="selectFilter($event)"
              (selectAllTypesEmitter)="selectFilters($event)"
              (selectAllEmitter)="selectFilters($event)"
            ></fc-filter-list>
          </fc-header-panel>
          <fc-filter-select
            *ngIf="selectedFilters()?.length && pageType() === 'vehicles'"
            [filters]="selectedFilters()"
            [borderTop]="true"
            (clearAllEmitter)="selectFilters([])"
            (deselectFilter)="selectFilter($event)"
          ></fc-filter-select>
          <div class="m-l-24 m-r-24 m-b-24">
            <fc-toggle-button-group
              [fullWidth]="true"
              data-cy="navigation-toggle"
              [value]="pageType()"
              (changed)="tabChanged()"
            >
              <fc-toggle-button
                *ngFor="let link of links"
                [routerLink]="link.url"
                [value]="link.url"
              >
                {{ link.name }}
              </fc-toggle-button>
            </fc-toggle-button-group>
          </div>
        </div>
        <div class="list">
          <router-outlet name="sidebar"></router-outlet>
        </div>
      </div>
      <div class="main">
        <router-outlet></router-outlet>
        <router-outlet name="modal"></router-outlet>
        <div
          class="vehicle-empty-state not-found"
          *ngIf="
            pageType() === 'vehicles' &&
            (allVehicles$ | async)?.length === 0 &&
            (vehicleLoading$ | async) === false
          "
        >
          <fc-empty-state
            icon="assets/img/vehicle-art.svg"
            title="Add your first vehicle"
            subtitle="There are no vehicles here yet"
            buttonText="Add vehicle"
            (buttonClicked)="addVehicle()"
          ></fc-empty-state>
        </div>
      </div>
    </fc-page-container>
  `,
  styleUrls: ['./vehicle-page.component.scss'],
  standalone: false,
})
export class VehiclePageComponent implements OnInit, OnDestroy {
  @HostBinding('style.top.px') containerTop: number;
  headerPanel: Signal<HeaderPanelComponent> = viewChild('headerPanel');
  vehicles$: Observable<Vehicle[]> = this.store.select(selectAllVehicles);
  vehicleLoading$: Observable<boolean> = this.store.select(getLoadingState);
  vehicleGroups$: Observable<VehicleGroup[]> =
    this.store.select(getAllVehicleGroups);
  vehicleTypes$: Observable<FilterGroup[]>;
  selectedFilters: Signal<FilterItem[]> =
    this.store.selectSignal(getSelectedFilters);
  links = [
    { name: 'Vehicles', url: 'vehicles' },
    { name: 'Groups', url: 'vehicle-groups' },
  ];
  sortByList$: Observable<SortBy[]>;
  sortBy: Signal<SortBy>;
  allVehicles$: Observable<Vehicle[]> = this.store.select(getAllRawVehicles);
  pageType: Signal<'vehicles' | 'vehicle-groups'> = this.store.selectSignal(
    selectRouterParamData('type'),
  );
  vehicleId: Signal<string> = this.store.selectSignal(selectRouterParam('id'));

  constructor(
    private store: Store<fromVehicles.State>,
    private router: Router,
  ) {
    effect(() => this.setSortVariables());
    effect(() => {
      if (this.vehicleId() === undefined && !isMobile()) {
        const itemsSelector: any =
          this.pageType() === 'vehicles'
            ? getAllRawVehicles
            : getAllVehicleGroups;
        const items: Signal<any[]> = this.store.selectSignal(itemsSelector);
        if (!items().length) return;
        this.router.navigate(
          [`vehicles/main/${this.pageType()}`, items()[0].id],
          { replaceUrl: true },
        );
      } else {
        this.routeChanged();
      }
      if (isMobile()) {
        this.headerPanel().closeSearch();
      }
    });
  }

  ngOnInit() {
    const vehicleLoadParams: VehicleOptions[] = vehicleLoadOptions;
    this.vehicleTypes$ = this.store.pipe(
      select(getUsedVehicleTypes),
      map((types) => [
        {
          name: 'Types',
          filters: types.map((type) => ({
            ...type,
            icon: type.slug,
            type: 'type',
          })),
        },
      ]),
    );
    this.store.dispatch(loadVehicles({ options: vehicleLoadParams }));
    this.store.dispatch(loadUsedVehicleTypes());
    this.store.dispatch(loadVehicleTypes());
    this.store.dispatch(loadVehicleGroups());
    this.store.dispatch(getAllHardware());
    this.store.dispatch(getAllHarness());
  }

  private getVehicleInfo(vehicleId: number) {
    if (!vehicleId) return;
    this.store.dispatch(selectVehicleId({ vehicleId }));
    this.store.dispatch(loadVehiclePhotos({ vehicleId }));
    this.store.dispatch(loadMaintenanceSchedules({ vehicleId }));
    this.store.dispatch(getVehicleStats({ vehicleId }));
  }

  routeChanged() {
    if (this.pageType() === 'vehicles') this.getVehicleInfo(+this.vehicleId());
    this.containerTop = this.vehicleId() ? 0 : 60;
  }

  tabChanged(): void {
    this.clearSort();
    this.headerPanel().closeSearch();
  }

  setSortVariables() {
    if (this.pageType() === 'vehicles') {
      this.sortByList$ = this.store.pipe(select(getVehicleSortByList));
      this.sortBy = this.store.selectSignal(getVehicleSortBy);
    } else {
      this.sortByList$ = this.store.pipe(select(getVehicleGroupSortByList));
      this.sortBy = this.store.selectSignal(getVehicleGroupSortBy);
    }
  }

  selectFilters(filters: FilterItem[]) {
    this.store.dispatch(setManyVehicleFilters({ filters }));
  }

  selectFilter(filterItem: FilterItem) {
    this.store.dispatch(setVehiclesFilter({ filterItem }));
  }

  onVehicleSearch(searchText: string): void {
    this.pageType() === 'vehicles'
      ? this.store.dispatch(setSearchVehicleText({ searchText }))
      : this.store.dispatch(setSearchVehicleGroupText({ searchText }));
  }

  ngOnDestroy(): void {
    this.vehicles$ = null;
    this.store.dispatch(setVehicleGroupFilterId({ vehicleGroupFilterId: -1 }));
    this.store.dispatch(setSearchVehicleText({ searchText: '' }));
    this.store.dispatch(setSearchVehicleGroupText({ searchText: '' }));
  }

  onSortByChange(sortBy: SortBy): void {
    this.pageType() === 'vehicles'
      ? this.store.dispatch(setVehicleSortBy({ sortBy }))
      : this.store.dispatch(setVehicleGroupSortBy({ sortBy }));
  }

  clearSort() {
    this.pageType() === 'vehicles'
      ? this.store.dispatch(resetDefaultVehicleFilters())
      : this.store.dispatch(resetDefaultVehicleGroupFilters());
  }

  addVehicle(): void {
    this.router.navigate([
      'vehicles/main/vehicles/',
      { outlets: { modal: ['add-vehicle'] } },
    ]);
  }
}
